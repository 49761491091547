import React, { useEffect } from 'react';
import { createContext, useReducer } from 'react';
import authReducer from './AuthReducer';
import axios from 'axios';
import { toast } from 'react-toastify';
const AuthContext = createContext();
// const API_URL = process.env.API_URL;
const identifier = window.location.hostname;

const apiUrl = process.env.REACT_APP_API_URL;
// const apiUrl = 'https://api.wholewayfitness.com/api'
export const AuthProvider = ({ children }) => {
	const initialState = {
		user: null,
		token: null,
		verified:false,
		loading: false,
	};

	//get the auhenthicated user on refresh
	const getLoggedInUserBasedOnToken = (token,verified) => {
		// console.log('called token login');
		
		axios
			.get(`${apiUrl}/v1/client?domain=${identifier}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
			
				if (res) {
					//console.log(res)
					const response = res.data.data;
					dispatch({
						type: 'LOGIN',
						payload: { user: response.client, token: token,verified:response.client.verified },
					});
				}
			})
			.catch((res) => {
				// console.log(res);
				logout();
				toast.error('Session expired, please login again');
				setLoading(false);
			});
	};
	useEffect(() => {
		const token = localStorage.getItem('auth-token');
		const verified = localStorage.getItem('verified');
		// attempt to relogin after hard page refresh
		if (token) {
			getLoggedInUserBasedOnToken(token,verified);
		}
	}, []);
	const [state, dispatch] = useReducer(authReducer, initialState);

	// login user 
	// param data[] = email, password, domain
	const login = async (data) => {
	
		setLoading(true);
		axios
		.post(`${apiUrl}/v1/client/login`,  { ...data })
			.then((res) => {
			
				if (res) {
					const response = res.data.data;
					dispatch({
						type: 'LOGIN',
						payload: { user: response.client, verified: response.client.verified ,token :response.token},
					});
					localStorage.setItem('auth-token', response.token);
					localStorage.setItem('verified', response.verified);
					
				}
			})
			.catch((res) => {
				const responseData = res.response.data;
				//show errors[] or message
				if(responseData.errors){
					const errorMessages = [];
					for (const key in responseData.errors) {
						if (responseData.errors.hasOwnProperty(key)) {
						const fieldErrors = responseData.errors[key];
						for (let i = 0; i < fieldErrors.length; i++) {
							errorMessages.push(fieldErrors[i]);
							toast.error(fieldErrors[i]);
						}
						}
					}
  					// toast.error(errorMessages.join('\n'));
				}
				else {
					toast.error( responseData.message);
				}
				setLoading(false);
			});
		return;
	};

	// Clear users from state

	const logout = () => dispatch({ type: 'LOGOUT' });
	const whoAmI = async () => {
		const token = localStorage.getItem('auth-token');
		
		// attempt to relogin after hard page refresh
		if (token) {
			return axios
				.get(`${apiUrl}/v1/client?domain=${identifier}`,  {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((res) => {
					updateUser(res.data.data);
					return res.data.data;
				}).catch((err) => {
					if(err.response.status === 401){
						logout();
						toast.error('Session expired, please login again');
					}
				});
		}
		return null;
	};
	const updateUser = (usr) => {
		
		dispatch({
			type: 'UPDATE_USER',
			payload: { user: usr.client },
		});
	};
	// Set loading
	const setLoading = (value) =>
		dispatch({ type: 'SET_LOADING', payload: value });
	const resetPw = (data) => {
		setLoading(true);
		// console.log(data);
		axios
			.post(`${apiUrl}/v1/client/password/email`, {
				email: data.email,
				domain: identifier,
				successUrl:
					window.location.protocol +
					'//' +
					window.location.host +
					'/reset-password',
					
			})
			.then((res) => {
				// console.log(res);
				if (res) {
					const response = res.data.data;
					// console.log(response);
					toast.success(response.message);
					setLoading(false);
				}
			})
			.catch((res) => {
				// console.log(res);
				toast.error(
					res.response.data.error?.email
						? res.response.data.error.email[0]
						: res.response.data.message
				);
				setLoading(false);
			});
	};
	window.logout = logout;
	return (
		<AuthContext.Provider
			value={{
				loading: state.loading,
				user: state.user,
				verified: state.verified,
				token: state.token,
				apiUrl,
				setLoading,
				logout,
				login,
				resetPw,
				updateUser,
				whoAmI,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
